body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/Roboto/Roboto-Regular.ttf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url(./fonts/Roboto/Roboto-Bold.ttf) format('opentype');
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.icon {
  font-size: 20px;
  line-height: 1em;
  max-width: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent; /* Hidden by default */
  transition: background-color 0.3s ease;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Visible on hover */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker on scrollbar thumb hover */
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: inherit;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

a:-webkit-any-link,
:any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

a:link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:visited {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.meal-icon {
  width: 37px;
  height: 37px;
}

.toggle-visibility {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.3s ease, max-height 0.5s ease;
  padding: 0;
}

.toggle-visibility.active {
  opacity: 1;
  max-height: 120px;
  height: auto;
}

.active-category-btn {
  color: 'kqn.copper';
  border-radius: '8px !important';
  background-color: '#fff';
}
.active-category-btn :hover {
  background-color: #eee;
}

.inactive-category-btn {
  color: #201d1d;
}
